import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"
import media from "../utils/media"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import Text from "../components/elements/text/text"
import H1Text from "../components/elements/text/h1-text"
import Line from "../components/elements/layout/line"

const FourOhFourContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.bgColorDark};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: auto;
  width: 100%;
  white-space: nowrap;
`

const HeroImage = styled(Img)`
  margin: 2em 0;
  width: 45em;
  ${media.phone`
    width: 30em;
  `};
`

const StyledHeadline = styled(H1Text)`
  font-family: ${({ theme }) => theme.fontFamilyRegular};
  color: ${({ theme }) => theme.fontColorWhiteReduced};
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 2em;
  ${media.phone`
  font-size: 2rem;
`};
`
const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledText = styled(Text)`
  margin: 0 1em;
  ${media.phone`
    font-size: 1rem;
  `};
`

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
`

const StyledLinkText = styled(Text)`
  ${media.phone`
    font-size: 1rem;
  `};
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background: ${p => p.theme.bgColorDark};
`

const StyledLogo = styled(Img)`
  width: 145px;
`

const LogoImage = ({ data }) => {
  return (
    <StyledLogo
      objectFit="cover"
      alt="Permian Map Logo"
      fluid={data.Logo236w.childImageSharp.fluid}
    />
  )
}

const Hero404Image = ({ data }) => {
  return (
    <HeroImage
      objectFit="cover"
      alt="404 Message"
      fluid={data.Hero1008w.childImageSharp.fluid}
    />
  )
}

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <LogoWrapper>
      <StyledLink to="/">
        <LogoImage data={data} />
      </StyledLink>
    </LogoWrapper>
    <FourOhFourContainer>
      <Hero404Image data={data} />

      <StyledHeadline weight="medium">Page Not Found</StyledHeadline>
      <Line />
      <ContentWrapper>
        <StyledText size="medium">
          We're sorry, the page you are looking for is no longer
          <br />
          here. Click to return to the{" "}
          <StyledLink to="/">
            <StyledLinkText color="yellow" size="medium">
              PermianMAP home page
            </StyledLinkText>
          </StyledLink>
        </StyledText>
      </ContentWrapper>
    </FourOhFourContainer>
  </Layout>
)
export const query = graphql`
  query {
    Logo236w: file(relativePath: { eq: "logo-236w.png" }) {
      childImageSharp {
        fluid(maxWidth: 236, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Hero1008w: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fluid(maxWidth: 1008, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default NotFoundPage
